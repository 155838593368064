<template>
  <div class="body">
    <div class="device-join">
      <!-- 头部部分 -->
      <div ref="search_d" style="margin-bottom: 10px">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="19">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="设备名称">
                <el-input
                  placeholder="请输入设备名称"
                  v-model="formInline.deviceName"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="点位名称">
                <el-input
                  placeholder="请输入点位名称"
                  v-model="formInline.pointName"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="网关编码">
                <el-input
                  placeholder="请输入网关编码"
                  v-model="formInline.gateWayCode"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="search">查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="newBtn">新建</el-button>
            <!-- <el-button type="primary" @click="downloadExcelTemplate">下载模板</el-button> -->
            <el-button type="primary" style="margin-left: 10px" @click="toInputBut">上传文件</el-button>
            <input type="file" id="default-btn" ref="fileInput" @change="importClick" style="display: none" />
            <exportBtn :params="expParams"/>
          </el-col>
        </el-row>
      </div>

      <!-- 表格部分 -->
      <div>
        <el-table border ref="table" :data="tableData" tooltip-effect="dark" style="width: 100%"
          height="calc(100vh - 240px)">
          <el-table-column label="操作" width="120" align="center" fixed>
            <template slot-scope="scope">
              <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button>
               <!-- <el-popconfirm title="确定删除吗？" @onConfirm="handleDelte(scope.row.id)">
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm> -->
              <el-button
                type="text"
                @click="handleDelte(scope.row.id)"
                >删除</el-button>
            </template>
          </el-table-column>
          <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>


          <el-table-column label="设备名称" align="center" min-width="160" show-overflow-tooltip>
            <template slot-scope="scope">{{
              scope.row.deviceName
            }}</template>
          </el-table-column>
          <el-table-column label="设备类型编码" align="center" min-width="160" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.deviceTypeCode }}</template>
          </el-table-column>
          <!-- <el-table-column label="数据类型名称" align="center" min-width="160" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.dataTypeName }}</template>
          </el-table-column> -->
          <el-table-column label="点位名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.pointName }}</template>
          </el-table-column>

          <el-table-column label="点位地址" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{
              scope.row.pointAddress
            }}</template>
          </el-table-column>
          <el-table-column label="网关编码" align="center" width="140" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.gateWayCode }}</template>
          </el-table-column>
          <el-table-column label="地址" width="140" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.address }}</template>
          </el-table-column>

        </el-table>
        <!-- 分页 -->
        <div class="block">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryInfo.currPage" :page-sizes="[20, 50, 100, 200]" :page-size="queryInfo.pageSize"
            class="pagination-style"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>

      <el-dialog :title="labelType == 'add' ? '新增' : '编辑'" :visible.sync="showDialog" width="800px">
        <el-form class="form-class" ref="form" :model="form" :rules="rules" label-width="120px">
          <el-row :gutter="10">

            <el-col :span="12">
              <el-form-item label="设备名称" prop="deviceSn">
                <el-select v-model="form.deviceSn" placeholder="请选择设备" clearable filterable style="width: 100%;">
                  <el-option v-for="item in deviceList" :key="item.id" :label="item.deviceName"
                    :value="item.deviceSn">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数据类型名称" prop="dataTypeName">
                <el-input v-model="form.dataTypeName" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数据类型编码" prop="dataTypeCode">
                <el-input v-model="form.dataTypeCode" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="点位名称" prop="pointName">
                <el-input v-model="form.pointName" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="点位地址" prop="pointAddress">
                <el-input v-model="form.pointAddress" onkeyup="if(/\D/.test(this.value)){this.value='';}" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="采集类型" prop="collectType">
                <el-select v-model="form.collectType" placeholder="请选择采集类型" clearable style="width: 100%;">
                  <el-option v-for="item in collectTypeList" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="网关编码" prop="gateWayCode">
                <el-input v-model="form.gateWayCode" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地址" prop="address">
                <el-input v-model="form.address" onkeyup="if(/\D/.test(this.value)){this.value='';}" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="参数类型" prop="paramType">
                <el-select v-model="form.paramType" placeholder="请选择参数类型" clearable style="width: 100%;">
                  <el-option v-for="item in paramTypeList" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="告警类型" prop="revision">
                <el-select v-model="form.revision" placeholder="请选择告警类型" clearable style="width: 100%;">
                  <el-option v-for="item in revisionList" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
        </el-form>
        <span slot="footer">
          <el-button size="small" @click="showDialog = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleSave">确定</el-button>
        </span>
      </el-dialog>

    </div>
  </div>
</template>
<script>
import axios from "axios";
import exportBtn from '@/components/exportBtn.vue'
export default {
  data() {
    return {
      labelType: "add",
      rules: {
        deviceSn: [{ required: true, message: "请选择设备名称" }],
        pointName: [{ required: true, message: "点位名称不能为空" }],
        gateWayCode: [{ required: true, message: "网关编码不能为空" }],
        collectType: [{ required: true, message: "请选择设备类型" }],
        paramType: [{ required: true, message: "请选择参数类型" }],
        revision: [{ required: true, message: "请选择告警类型" }],
      },
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {},
      },
      form: {},
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 256, //表格高度
      headerHeight: 0,
      formInline: {},
      deviceTypeList: [], //设备类型
      getdeviceTypeName: {},
      powerStationList: [], //电站
      getpowerStationName: {},
      collectTypeList: [
        { value: 0, label: '网关' },
        { value: 1, label: '数据棒' }
      ],
      paramTypeList: [
        { value: 0, label: '电量' },
        { value: 1, label: '电流' },
        { value: 2, label: '电压' },
        { value: 3, label: '故障码' },
        { value: 4, label: '功率' }
      ],
      revisionList: [
        { value: 0, label: '正常状态' },
        { value: 1, label: '紧急' }
      ],
      deviceList: [],
      deviceListData: {},
      expParams:{
        url:'',
        fileName:''
      },
    };
  },
  components:{
    exportBtn
  },

  created() {
    this.getList();
    this.getSelectList();
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },

  methods: {
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 240;
    },

    //获取数据
    async getList() {
      const res = await this.$http.post(
        "/devicePoint/list",
        this.queryInfo
      );
      if (res.data.code === 200) {
        this.total = res.data.data.count;
        this.tableData = res.data.data.data;
      }
    },
    // 查询
    search() {
      this.queryInfo.condition = this.formInline;
      console.log(this.formInline, 357);
      this.getList();
    },

    getSelectList() {
      // 设备列表
      const _queryInfo = {
        pageSize: 1000000,
        currPage: 1,
        condition: {},
      }
      this.$http
        .post("powerStationDevice/list", _queryInfo)
        .then((res) => {
          if (res.data.code == 200) {
            this.deviceList = res.data.data.data;
            this.deviceList.forEach(item => {
              this.deviceListData[item.deviceSn] = {
                deviceName: item.deviceName,
                deviceTypeCode: item.deviceTypeCode,
                deviceTypeName: item.deviceTypeName,
              }
            })
          } else {
            this.$message.error(res.data.message);
          }
        });
    },

    // 保存
    handleSave() {
      var that = this;
      console.log('form',that.form)
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const _form = {
            ...that.form,
            ...that.deviceListData[that.form.deviceSn]
          }
          that.$http
            .post("/devicePoint/save", _form)
            .then(function (response) {
              if (response.data.code == 200) {
                that.$notify.success({
                  title: "提示",
                  message: "保存成功",
                  showClose: true,
                });
                that.showDialog = false;
                that.getList();
              } else {
                that.$notify.info({
                  title: "提示",
                  message: response.data.message,
                  showClose: true,
                });
              }
            })
            .catch(function (error) { });
        }
      });
    },

    //修改
    edit(row) {
      this.showDialog = true;
      this.labelType = "edit";
      this.form = JSON.parse(JSON.stringify(row));
    },
    changeDeviceTypeCpde(val) {
      console.log(111);
      console.log(val, 429);
    },


    handleDelte(id) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.$http.post("/devicePoint/delete", {
            id: id,
          });
          if (res.data.code === 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          }
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.form = {};
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
    toInputBut() {
      this.$refs.fileInput.click();
    },
    async importClick(event) {
      console.log("导入", event);
      const file = event.target.files[0];
      // 使用 FormData 包装文件
      const formData = new FormData();
      formData.append("file", file);
      // formData.append("powerStationCode", this.formInline.powerStationCode);
      // formData.append("powerStationName", this.getpowerStationName[this.formInline.powerStationCode]);
      console.log("导入--", file);
      const res = await this.$http.post("/devicePoint/import", formData, {
              headers: {
                  'Content-Type': 'multipart/form-data', // 设置正确的Content-Type
              },
          });
      if (res.data.code === 200) {
        this.getList();
        that.$notify.success({
          title: "提示",
          message: "导入成功",
          showClose: true,
        });
      } else {
        this.$notify.info({
          title: "提示",
          message: res.data.message,
          showClose: true,
        });
      }
    },
    // 下载模版
    async downloadExcelTemplate() {
      this.$message.success("开始下载...请稍等");
      const res = await axios({
        method:'get',
        url: "/powerStationDevice/export",
        responseType: "blob", // 设置响应类型为blob，用于处理文件流}, {}, {
        // headers: {
        //   "Content-Type": "multipart/form-data",
        //   Accept: "application/json", // 告诉服务器您希望接收JSON响应
        // },
      });

      const blob = new Blob([res.data]);
      // 创建一个临时的URL
      const url = window.URL.createObjectURL(blob);
      // 创建一个<a>标签用于下载
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // 设置下载的文件名
      a.download = "template.xlsx";
      document.body.appendChild(a);
      a.click();
      // 释放URL对象
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

    },
    exportClick() {
      const url =
        "http://192.168.31.189:9001/api/powerStationDeviceJoin/export";
      axios({
        url: url,
        method: "POST",
        responseType: "blob", // 重要：设置响应类型为blob，以便处理二进制文件
      })
        .then((response) => {
          console.log(response);
          // 创建一个新的URL代表指定的File对象或Blob对象
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          // 创建一个a标签用于下载
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "excel.csv"); // 下载文件名
          document.body.appendChild(link);
          link.click();

          // 清理
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("导出文件失败:", error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.body {
  background: #223f6c;
  padding: 10px;
  height: 100%;

  .device-join {
    height: 100%;
    background: #2d5981;
    padding: 8px;

    #default-btn::file-selector-button {
      padding: 5.5px 10px;
      background-color: #409eff;
      border: 1px solid #409eff;
      border-radius: 3px;
      cursor: pointer;
      color: #fff;
      font-size: 12px;
    }

    .demo-form-inline{
      /deep/.el-form-item{
        margin-bottom: 0;
      }
    }
    .form-class {
      /deep/ .el-form-item__label {
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
        font-size: 16px;
        padding-top: 5px;
        box-sizing: border-box;
      }

      /deep/ .el-input__inner {
        border-radius: 5px;
        border: 1px solid rgba(151, 179, 203, 0.55);
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
      }
      /* 表单 */
      /deep/.el-select{
        width: -webkit-fill-available;
      }
    }

    /deep/ .el-input-group__append {
      background: #409eff;
      color: #fff;
    }

    /deep/ .el-form-item__label {
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
      font-size: 16px;
    }

    /deep/ .el-input__inner {
      border: 1px solid rgba(151, 179, 203, 0.55);
      background-color: rgba(255, 255, 255, 0);
      font-family: "Arial", sans-serif;
      color: #f2f2f2;
    }

    /deep/ .el-table--border,
    .el-table--group {
      border: 1px solid rgba(151, 179, 203, 0.55);
    }

    /deep/ .el-table--border th {
      background-color: #21527e;

      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    /deep/ .el-table th {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    /deep/ .el-table td {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    /deep/ .el-table__body-wrapper {
      background: #2d5981;
    }

    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
      background-color: #223f6c !important;
    }

    /deep/ .el-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }

    /deep/ .el-pagination button:disabled {
      background: rgba(255, 255, 255, 0);
    }

    /deep/ .el-pager li.active {
      color: #f2f2f2;
      background-color: #00b2e6;
      cursor: default;
    }

    /deep/ .el-pager li {
      background: none;
      color: #f2f2f2;
    }

    /deep/ .el-icon {
      color: #fff;
    }

    /deep/ .el-pagination .btn-prev {
      background: none;
    }

    /deep/ .el-pagination .btn-next {
      background: none;
    }

    /deep/ .el-pagination__total {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 12px;
    }

    /deep/ .el-pagination__jump {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 15px;
    }
  }
}

/deep/ .el-icon-close:before {
  color: #333;
}

#default-btn::file-selector-button {
  padding: 5.5px 10px;
  background-color: #409eff;
  border: 1px solid #409eff;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
}

#default-btn {
  font-size: 0;
  margin: 0;
  padding: 0;
  // margin-left: 10px;
  // margin-right: 6px;
  // margin: 1px 6px 0 10px;
}

::v-deep .el-table--small .el-table__cell {
  padding: 0 !important;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: #f59a23 !important;
}

::v-deep .el-form-item__label {
  text-align: end !important;
}

::v-deep .el-dialog__body {
  padding: 0 30px !important;
  text-align: center;
}

::v-deep .el-form-item--small.el-form-item {
  height: 36px !important;
}
</style>
